import React, { Component } from "react";
import { Link } from 'react-router-dom';
import "./Project.css";
import ProjectInfo from "./ProjectInfo";
import {Route} from "react-router-dom";
import { ROUTES } from "../../components/router/routes";


class Project extends Component{
    render(){
        const { name, date } = this.props;

        return (
            <div className="project">
                <div className="container">
                    <li className="navbar-item">
                        <Link to={ROUTES.PROJECT_DETAILS} className="nav-link">{name}</Link>
                     </li>
                </div>
            </div>
          );
    }
}


export default Project;
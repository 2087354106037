import { handleResponse, URLS, getAccessToken, uuidv4 } from "../../utils";
import AWS from 'aws-sdk';

//TODO FIND ALTERNATIVE!!! use signed url
//TODO move const in utils.js
const s3 = new AWS.S3({
    accessKeyId: 'AKIAU7A6HHUS2R6DAUO3',
    secretAccessKey: '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',
    region: 'ca-central-1'
});

export async function getAllProjects() {
    const req = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'BEARER ' + getAccessToken(),
        },
        };
    const url = URLS.PUBLIC_API + "/getAllProjects";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
}

export async function uploadFile(file, projectId, email) {
  var fileExt = file.name.split('.').pop();
  var fileKey = projectId + '/' + uuidv4() + '.' + fileExt

  const promise = new Promise(function(resolve, reject) {
    s3.upload({
      Bucket: 'ratio-dev-raw',
      Key: fileKey,
      Body: file,
      Metadata: {
        'filename': file.name,
        'author': email
      },
    }, function(err, data) {
      if (err) {
        reject("Error uploading file.");
      }
        resolve({ objectKey: fileKey, name: file.name });
    });
  });

  const response = await promise;
  //TODO: handle error.
  return { result: response };
}

export async function getFileMetaData(bucket, key) {
  return new Promise(function(resolve, reject) { 
    s3.headObject({
      Bucket: bucket,
      Key: key,
    }, (err, data) => {
      if (err) {
        reject(err);
      }
      else {
        resolve(data.Metadata.filename || "name undef.");
      }
    });
  });
}

export async function loadProjectFiles(projectId) {
  const promise = new Promise(function(resolve, reject) {
    var params = {
      Bucket: 'ratio-dev-raw',
      Prefix: projectId + '/'  // Can be your folder name
    };
    s3.listObjectsV2(params, async function(err, data) {
      const results = [];
      if (err) reject(err);

      for (let index = 0; index < data.Contents.length; index++) {
        const element = data.Contents[index];
        const ogName = await getFileMetaData('ratio-dev-raw', element.Key);
        if (ogName) {
          results.push({ ...element, originalName: ogName });
        }
      }

      resolve(results);
    });
  });

  const response = await promise;
  //TODO: handle error.
  return { result: response };
}


export async function extractRatio(fileName) {
  const req = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'BEARER ' + getAccessToken(),
      },
      body: JSON.stringify({fileName})
    };
  const url = URLS.PUBLIC_API + "/readFile";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}

export async function createProject(name) {
  const req = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'BEARER ' + getAccessToken(),
      },
      body: JSON.stringify({name})
    };
  const url = URLS.PUBLIC_API + "/createProject";
  const response = await fetch(url, req);
  return handleResponse({ url, ...req }, response);
}


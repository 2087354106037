import { handleResponse, URLS } from "../../utils";

export async function signup(email, name, password) {
    const req = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, password, name})
      };
    const url = URLS.PUBLIC_API + "/register";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }
  

  export async function confirm(payload) {
    const req = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      };
    const url = URLS.PUBLIC_API + "/confirme";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function login(email, password) {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password })
    };
    const url = URLS.PUBLIC_API + "/login";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }

  export async function updatePassword(data) {
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    const url = URLS.PUBLIC_API + "/changePassword";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
  }
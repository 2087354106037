import { takeLatest, call, takeEvery, put, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { ROUTES } from '../../components/router/routes';
import { customHistory } from '../../components/router/router';
import { getAllProjects, uploadFile, loadProjectFiles, extractRatio, createProject } from './api';
import { formatDate } from '../../utils';

function* watchCreateProject(action) {
    try {
        const { title } = action;
        const current_datetime = new Date();
        const formatted_date = formatDate(current_datetime);
        const project = { id: -1, name: title, date: formatted_date };
        yield call(createProject, title);
        yield put(actions.createProjectSuccess(project));
        customHistory.push(ROUTES.HOME);
    
    } catch (e) {
        console.log(e);
    }
}

function* watchGetAllProjects(action) {
    try { 
        const {result} = yield call(getAllProjects);
        yield put(actions.getAllProjectsSuccess(result));
    } catch(e) {
        console.log(e);
    }
}

function* watchUploadFile(action) {
    try{
        const { file, projectId } = action;
        const currentUser = yield select(state => state.authReducer.user);
        const response = yield call(uploadFile, file, projectId, currentUser.email);
        if (response) {
            yield put(actions.uploadFileSuccess(response.result));
        }
    }
    catch(e) {
        console.log(e);
    }
}

function* watchGetProjectFiles(action) {
    try {
        const { projectId } = action;
        const { result } = yield call(loadProjectFiles, projectId);
        if (result) {
            const files = result.map(item => ({ size: item.Size, name: item.originalName, objectKey: item.Key, uploaded_date: item.LastModified }));
            yield put(actions.getProjectFilesSuccess(files));
        }
    }
    catch(e) {
        console.log(e);
    }
}

function* watchExtractRatio(action) {
    try {
        const { file } = action;
        const { result } = yield call(extractRatio, file.objectKey);
        if (result) {
          let newFile = {...file, result};
           yield put(actions.extractRatioSuccess(newFile));
        }
    }
    catch(e) {
        console.log(e);
    }
}

export default function* rootSaga() {
    yield takeLatest(actionTypes.CREATE_PROJECT, watchCreateProject);
    yield takeLatest(actionTypes.GET_ALL_PROJECTS, watchGetAllProjects);
    yield takeEvery(actionTypes.UPLOAD_FILE, watchUploadFile);
    yield takeLatest(actionTypes.GET_PROJECT_FILES, watchGetProjectFiles);
    yield takeLatest(actionTypes.EXTRACT_RATIO, watchExtractRatio);
}

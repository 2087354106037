import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import { setInput } from '../../../utils';

const ChangePasswordForm = (props) => {

    const [prevPassword, setPrevPassword] = useState("");
    const [newPass, setNewPass] = useState("");
    const [newPass2, setNewPass2] = useState("");

    const submit = (e) => {
        e.preventDefault();
    
        if (!prevPassword || !newPass || !newPass2) {
            //props.createProject(title);
            alert("All fields are required.")
        }
        else if (newPass != newPass2) {
            alert("Your passwords don't match");
        }
        else {
            props.updatePassword(newPass, prevPassword);
        }
    }    
    
    return (
        <form onSubmit={submit}>
            <TextField
                id="outlined-password-input"
                label="Previous Password"
                type="password"
                margin="normal"
                variant="filled"
                fullWidth
                value={prevPassword}
                onChange={setInput(setPrevPassword)}
            />
            <TextField
                id="outlined-password-input"
                label="Password"
                type="password"
                margin="normal"
                variant="filled"
                fullWidth
                value={newPass}
                onChange={setInput(setNewPass)}
            />
            <TextField
                id="outlined-password-input"
                label="Password"
                type="password"
                margin="normal"
                variant="filled"
                fullWidth
                value={newPass2}
                onChange={setInput(setNewPass2)}
            />
            <Button type="submit" size="small" color="primary">Update Password</Button>
        </form>    
    )
}

export default ChangePasswordForm;
import { CREATE_PROJECT, CREATE_PROJECT_SUCCESS, GET_ALL_PROJECTS, GET_ALL_PROJECTS_SUCCESS, UPLOAD_FILE, UPLOAD_FILE_SUCCESS, GET_PROJECT_FILES, GET_PROJECT_FILES_SUCCESS, EXTRACT_RATIO, EXTRACT_RATIO_SUCCESS } from './actionTypes';

export const createProject = (title) => {
    return {
        type: CREATE_PROJECT,
        title,
    }
}

export const createProjectSuccess = (project) => {
    return {
        type: CREATE_PROJECT_SUCCESS,
        project,
    }
}

export const getAllProjects = () => {
    return {
        type: GET_ALL_PROJECTS
    }
}

export const getAllProjectsSuccess = (projects) => {
    return {
        type: GET_ALL_PROJECTS_SUCCESS,
        projects
    }
}

export const uploadFile = (file, projectId) => {
    return {
        type: UPLOAD_FILE,
        file,
        projectId
    }
}

export const uploadFileSuccess = (file) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        file
    }
}

export const getProjectFiles = (projectId) => {
    return {
        type: GET_PROJECT_FILES,
        projectId
    }
}

export const getProjectFilesSuccess = (files) => {
    return {
        type: GET_PROJECT_FILES_SUCCESS,
        files
    }
}


export const extractRatio = (file) => {
    return {
        type: EXTRACT_RATIO,
        file
    }
}

export const extractRatioSuccess = (result) => {
    return {
        type: EXTRACT_RATIO_SUCCESS,
        result
    }
}




export const handleResponse = async (request, response) => {
    let result;
    try {
      result = await response.json();
    } catch (err) {
     //response is empty but 200...
      if (response.ok) {
        return {
          result: "OK",
        }
      }
      throw Object.assign(new Error("Unhandled Error"));
    };
    if (!response.ok) {
      const genericMessage = `HTTP ${response.status} (${response.statusText})`;
      throw Object.assign(new Error(genericMessage), {
        error: result,
        status: response.status
      });
    }
    return {
      response, 
      result 
    };
};


export const URLS = {
    PUBLIC_API: "//devapi.ratio.protogest.net"
}

export const setInput = (fn) => (event) => {
    fn(event.target.value);
}
 
export const getAccessToken = () => {
    return localStorage["accessToken"];
};

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

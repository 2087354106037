    import { CREATE_PROJECT_SUCCESS, GET_ALL_PROJECTS_SUCCESS, GET_PROJECT_FILES_SUCCESS, EXTRACT_RATIO_SUCCESS, UPLOAD_FILE_SUCCESS } from './actionTypes';

    export default (state = {}, action) => {
    switch (action.type) {
      case CREATE_PROJECT_SUCCESS: 
        return {
          ...state,
          projects: [...(state.projects || []), action.project]
        }
      case GET_ALL_PROJECTS_SUCCESS: 
        return {
          ...state,
          projects: action.projects
        }
      case GET_PROJECT_FILES_SUCCESS:
        return {
          ...state,
          files: action.files,
          selectedFile: undefined
        }
      case EXTRACT_RATIO_SUCCESS:
        return {
          ...state,
          selectedFile: action.result
        }
      case UPLOAD_FILE_SUCCESS: 
        return {
          ...state,
          files: [
            ...(state.files || []),
            action.file
          ]
        }
      default:
        return state;
    }
  };
  
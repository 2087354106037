import React, { Component } from "react";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import "./Project.css";
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';

import Star from '@material-ui/icons/Star';
import FavoriteBorder from '@material-ui/icons/StarBorder';
import { uploadFile, getProjectFiles, extractRatio } from '../../services/projectService/actions';
import { ROUTES } from "../../components/router/routes";
import { ButtonBase, FormControlLabel, Checkbox } from "@material-ui/core";
import { relative } from "path";

class ProjectInfo extends Component{

    constructor(props) {
        super(props);
          this.state = {
            selectedFile: null
          }
        }

    uploadFile = (event) => {
      const file = document.getElementById("file");
      if (file.files && file.files.length > 0) {
        for (let index = 0; index < file.files.length; index++) {
          const element = file.files[index];
          this.props.uploadFile(element, this.props.location.state.id);
        }
      }
    }

    componentDidMount() {
      const { location } = this.props;
      if (location && !location.state) {
        this.props.history.push(ROUTES.HOME);
      }
      this.props.getProjectFiles(location.state.id);
    }

    extractRatio = (file) => () => {
      this.props.extractRatio(file);
    }

    triggerInputFile = () => this.fileInput.click()

    render(){
        const { location: { state: project }, files, selectedFile } = this.props;
  
        const style = {
          container: {
            display: "flex",
          },
          uploadedFiles: {
            maxWidth: "360px",
            background: "white",
            marginTop: "1rem",
            padding: "1rem",
            borderRadius: "5px",
            width: "360px",
            height: "fit-content"
          },
          analysedFile: {
            flex: 1,
            marginTop: "1rem",
            marginLeft: "1rem",
            background: "white",
            borderRadius: "5px",
            padding: "1rem"
          },
          homeBtn: {
            marginBottom: "0.5rem",
            position: "relative",
            right: "0.5rem",
          }
        }
        
        return (
            <div className="project" style={{ textAlign: "left"}} >
              <Link component={RouterLink} to={ROUTES.HOME}>
                  <Button style={style.homeBtn} type="button" size="small" color="primary">Back to Projects</Button>
              </Link>
              <div style={{marginBottom: "1em"}}>
                <Typography gutterBottom variant="h5" component="h1">
                      { project.name }
                </Typography>
                <Typography gutterBottom variant="p">
                      { project.date }
                </Typography>
              </div>              
              <CardActions className="project__actions">
                <input ref={fileInput => this.fileInput = fileInput}  style={{ display: "none" }} id="file" type="file" name="file" onChange={this.uploadFile} multiple/>
                <Button variant="contained" onClick={this.triggerInputFile} color="primary"> Upload Documents </Button>
              </CardActions>
              <div style={style.container}>
                {
                  files &&
                  <div style={style.uploadedFiles}>
                    <Typography variant="h6">
                      Uploaded Files
                    </Typography>
                    <div>
                      {
                        files && files.length === 0 && <p> No file available. </p>
                      }
                      <List>
                        {
                            files.map((file, index) => <ListItem key={"file-" + index}>
                              <FormControlLabel
                                control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Star />} value="checkedH" />}
                              />
                              <ListItemAvatar>
                                <Avatar>
                                  <FolderIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={file.name}
                              />
                              <ListItemSecondaryAction>
                                <ButtonBase onClick={this.extractRatio(file)}>
                                  Read
                                </ButtonBase>
                              </ListItemSecondaryAction>
                            </ListItem>)
                          }
                      </List>
                    </div>
                  </div>
                }
                {
                  selectedFile && 
                  <div style={style.analysedFile}>
                      <Typography gutterBottom variant="h5" component="h2">
                        Result: {selectedFile.name}
                      </Typography>
                      <pre>{selectedFile.result}</pre>
                  </div>
                }
               </div>
            </div>
          );
    }
    onChangeHandler=event=>{

      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
      
    }
}


export default connect(state => ({ files: state.projectReducer.files, selectedFile: state.projectReducer.selectedFile }),{ uploadFile, getProjectFiles, extractRatio })(ProjectInfo);
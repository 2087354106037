import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { setInput } from '../../utils';
import './style.css';

const ProjectCreationForm = (props) => {

    const classes = {};

    const [title, setTitle] = useState();

    const save = () => {
        if (title && title.length > 1) {
            props.createProject(title);
        }
        else {
            alert("Le titre doit être au moins 2 caractères.")
        }
    }

    return <div className="project-creation-form">
    <Card className={classes.card}>
    <form className={classes.container} noValidate autoComplete="off">
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Create a Project
        </Typography>
       
            <div className="project__upload">

                    <TextField
                        label="Project Title"
                        className={classes.textField}
                        type="text"
                        margin="normal"
                        variant="filled"
                        fullWidth
                        value={title}
                        onChange={setInput(setTitle)}
                    />

            </div>
        </CardContent>
        <CardActions className="project__actions">
                <Button type="button" onClick={save} size="small" color="primary">
                    Create
                </Button>
            </CardActions>
        </form>
    </Card>
  </div>
}

export default ProjectCreationForm;
import React from 'react';
import { Container, Typography } from '@material-ui/core';
import Accordion from './components/accordion';
const Profile = (props) => {
    const { user } = props; 
    return (
        <Container style={{textAlign: "left"}} maxWidth="md">
            <Typography variant="h5" component="h2">Your Profile Settings</Typography>
            <div style={{marginTop: "1em"}}>
                <Accordion 
                    user={user}
                    updatePassword={props.updatePassword} 
                />
            </div>
        </Container>
    );
}

export default Profile;
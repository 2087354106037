export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";

export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

export const GET_PROJECT_FILES = "GET_PROJECT_FILES";
export const GET_PROJECT_FILES_SUCCESS = "GET_PROJECT_FILES_SUCCESS";

export const EXTRACT_RATIO = "EXTRACT_RATIO";
export const EXTRACT_RATIO_SUCCESS = "EXTRACT_RATIO_SUCCESS";



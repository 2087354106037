import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import WorkIcon from '@material-ui/icons/Work';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { ROUTES } from '../../components/router/routes';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginBottom: '1em'
  },
  actions: {
      textAlign: "right"
  }
}));

const Home = (props) => {
    const [projects, setProjects] = useState();
    const classes = useStyles();

    useEffect(() => { 
        if (!props.projects) { 
            props.getAllProjects() 
        }
    }, []);
    
    return (<div className="container">
        <Typography gutterBottom variant="h5" component="h2">
           Mes Projets
        </Typography>
        <div className={classes.actions}>
            <Link component={RouterLink} to={ROUTES.CREATE_PROJECT}>
                <Button variant="contained" color="primary" className={classes.button}>
                    New Project
                </Button>
            </Link> 
        </div>
        <List className={classes.root}>
            {
                props.projects && props.projects.map(project => {
                    return (
                        <Link component={RouterLink} to={{ pathname: ROUTES.PROJECT_DETAILS_FORMATTED(project.id), state: project }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                    <WorkIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={project.name} secondary={project.date} />
                            </ListItem>
                        </Link>
                    );
                })
            }
            </List>
        </div>
    );
}

export default Home;


